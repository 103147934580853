import { combineReducers } from 'redux'
import authReducer from './auth.js'
import userTempEmailReducer from './userTempEmailReducer.js'
import participantReducer from './participantReducer.js'
import nonMeetingReducer from './nonMeetingReducer.js'
import meetingStartTimesReducer from './meetingStartTimesReducer'
const allReducers = combineReducers({
  auth: authReducer,
  userTempEmail: userTempEmailReducer,
  participants: participantReducer,
  nonmeetings: nonMeetingReducer,
  meetingstimings: meetingStartTimesReducer
})
export default allReducers
