import { NON_MEETING_DATA } from '../types'

const INITIAL_STATE = {
  nonmeetings: {}
}
const nonMeetingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NON_MEETING_DATA:
      return {
        ...state,
        nonmeetings: action.payload
      }
    default:
      return state
  }
}
export default nonMeetingReducer
