import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'auth',
    'userTempEmail',
    'participants',
    'nonmeetings',
    'meetingstimings'
  ]
}

const pReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  pReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
const persistor = persistStore(store)
export { persistor, store }
