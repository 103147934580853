import { MEETINGS_START_TIMES } from '../types'

const INITIAL_STATE = {
  meetingstimings: []
}
const meetingStartTimesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MEETINGS_START_TIMES:
      return {
        ...state,
        meetingstimings: action.payload
      }

    default:
      return state
  }
}
export default meetingStartTimesReducer
