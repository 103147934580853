import { PARTICIPANTS_DATA, REMOVE_PARTICIPANTS_DATA } from '../types'

const INITIAL_STATE = {
  participants: []
}
const participantReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PARTICIPANTS_DATA:
      return {
        ...state,
        participants: action.payload
      }

    case REMOVE_PARTICIPANTS_DATA:
      return {
        participants: []
      }

    default:
      return state
  }
}
export default participantReducer
