import { USER_TEMP_EMAIL } from "../types";

const INITIAL_STATE = {
  user_temp_email: "",
};
const userTempEmailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_TEMP_EMAIL:
      return {
        ...state,
        user_temp_email: action.payload,
      };
    default:
      return state;
  }
};
export default userTempEmailReducer;
