import React, { Suspense, lazy } from 'react'
// import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
const LandingPage = lazy(() => import('./pages/landing'))
const SignUpPage = lazy(() => import('./pages/signup'))
const SalaryQuestionnaire = lazy(() => import('./pages/salaryQuestionnaire'))
const FrequentColleages = lazy(() => import('./pages/frequentcolleages'))
const Dashboard = lazy(() => import('./pages/dashboard'))
const CalenderIntegration = lazy(() => import('./pages/calenderIntegration'))
const GoogleOAuthCallback = lazy(() => import('./pages/googleOAuthCallback'))
const MidWayQuesition = lazy(() => import('./pages/middlewareQuesition'))
const ProtectedRoutes = lazy(() => import('./routesRoles/protectedRoutes'))
const AuthRoutes = lazy(() => import('./routesRoles/authRoutes'))

function App () {
  return (
    <Router>
      <Suspense
        fallback={
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 36,
              fontVariant: 'oldstyle-nums'
            }}
          >
            Loading...
          </div>
        }
      >
        <Switch>
          <AuthRoutes exact path='/'>
            <LandingPage />
          </AuthRoutes>

          <AuthRoutes exact path='/signup'>
            <SignUpPage />
          </AuthRoutes>

          <ProtectedRoutes exact path='/salary-quesitionare'>
            <SalaryQuestionnaire />
          </ProtectedRoutes>

          <ProtectedRoutes exact path='/attention'>
            <MidWayQuesition />
          </ProtectedRoutes>

          <ProtectedRoutes exact path='/frequent-colleagues'>
            <FrequentColleages />
          </ProtectedRoutes>

          <ProtectedRoutes exact path='/dashboard'>
            <Dashboard />
          </ProtectedRoutes>

          <ProtectedRoutes path='/google/callback'>
            <GoogleOAuthCallback />
          </ProtectedRoutes>

          <ProtectedRoutes exact path='/connect-calender'>
            <CalenderIntegration />
          </ProtectedRoutes>

          <Route
            path='*'
            component={() => (
              <div>
                <h1 style={{ textAlign: 'center' }}>
                  404 - Not Found!
                  <br />
                  {/* <Link to="/">Go Home</Link> */}
                </h1>
              </div>
            )}
          />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
